import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getDiscountGroups: createCancelTokenHandler('getDiscountGroups')
}


export function getDiscountGroups (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/club/discount-groups${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getDiscountGroups'].handleRequestCancellation().token
  })
}

export function getActiveDiscountGroup (id) {
  return axios({
    url: `v1/admin/club/discount-groups/${id}`,
    method: 'get'
  })
}

export function editDiscountGroups (payment) {
  return axios.patch('v1/admin/club/discount-groups', payment)
}
